import { ALL_LOCATIONS } from '@/constants/jobLocations';
import { IQuery } from '@/types/alerts';
import { parseAndFormatDate } from '@/utils/dateHelper';
import {
  convertLocationStringToLocationDisplayList,
  convertLocationsToValues,
  generateLocationNameFromLocationString,
} from '@/utils/jobLocationsHelper';
import axios from 'axios';
import { PATH } from 'constants/path';
import dayjs from 'dayjs';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { slug } from 'to-case';
import {
  IGrade,
  IJobDetail,
  ISpeciality,
  JobGrade,
  JobListPaginatedResults,
  JobSearchResultDetail,
  JobSearchWebCriteria,
} from 'types/jobs';
import { User } from 'types/user';
import { UrlObject } from 'url';

import { appServerConfig } from './appServerConfig';

/**
 * Generates the job listing urls for sitemap in the SEO friendly way
 * @returns array of strings - urls
 * @throws error if exception - calling method to handle it
 */
export const generateJobListingUrlsForSitemap = async (): Promise<Array<string>> => {
  const jobListingUrls: string[] = [];

  const api = axios.create({
    baseURL: appServerConfig.recruitApiUrl,
  });
  let { data: grades }: { data: Array<IGrade> } = await api.get('/v1/picklist/grades');

  grades = grades.filter(item => item.key !== 'Medical Student');

  const gradeSpecialities: { grade: JobGrade; specialties: ISpeciality[] }[] = await Promise.all(
    grades.map(async grade => {
      const { data: specialties }: { data: Array<ISpeciality> } = await api.get(
        `/v1/picklist/${encodeURIComponent(grade.key)}/specialities`,
      );
      return {
        grade: grade.key,
        specialties: specialties,
      };
    }),
  );

  for (const gradeSpeciality of gradeSpecialities) {
    jobListingUrls.push(generateJobListingsURL(gradeSpeciality.grade));
    for (const speciality of gradeSpeciality.specialties) {
      jobListingUrls.push(generateJobListingsURL(gradeSpeciality.grade, speciality.key));
    }
  }

  return jobListingUrls;
};

/**
 * Generates the jobs listing pathname
 *
 * @param grade
 * @param speciality
 * @param rootSpeciality root speciality which is used when feature flag is on
 * @param subSpeciality sub speciality which is used when feature flag is on
 * @returns string with format /jobs/listing/${grade}/${speciality}
 */
export const generateJobListingsURL = (
  grade?: string,
  speciality?: string,
  rootSpeciality?: string,
  subSpeciality?: string,
) => {
  if (!grade) {
    return `${PATH.JOBSLIST}`;
  }
  let jobListingBaseUrl = `${PATH.JOBSLISTSEO}/`;

  if (grade) {
    jobListingBaseUrl += `${slug(grade.toLowerCase())}/`;
  }
  if (speciality) {
    jobListingBaseUrl += `${slug(speciality.toLowerCase())}/`;
  }
  if (rootSpeciality) {
    jobListingBaseUrl += `${slug(rootSpeciality.toLowerCase())}/`;
  }
  if (subSpeciality) {
    jobListingBaseUrl += `${slug(subSpeciality.toLowerCase())}/`;
  }

  return jobListingBaseUrl;

  // if (grade && speciality) {
  //   return `${PATH.JOBSLISTSEO}/${slug(grade.toLowerCase())}/${slug(speciality.toLowerCase())}`;
  // }

  // if (grade) {
  //   return `${PATH.JOBSLISTSEO}/${slug(grade.toLowerCase())}`;
  // }
};

//More details at https://dev.to/harry0000/a-bit-convenient-typescript-type-definitions-for-objectentries-d6g
type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}
/**
 *  Remove params from the query string if they:
 *  - are falsy: undefined, null etc.
 *  - have 'none' as value: could come from form controls like grade, specialty, date
 *  - have 'listing' as key: could come from /jobs/listing/<grade>/<specialty> route as a param
 */
export function purgeQuery<T extends object>(query: T): T {
  const purgedQuery = { ...query };
  for (const [key, value] of objectEntries(query)) {
    if (!value || value === 'none' || key === 'listing') {
      delete purgedQuery[key];
    }
  }
  return purgedQuery;
}

/**
 * Generates the jobs listing url object
 *
 * @param query
 * @returns UrlObject
 */
export const generateJobListingsUrlObject = (
  query: JobSearchWebCriteria & { listing?: string },
): UrlObject => {
  const purgedQuery = purgeQuery<JobSearchWebCriteria>(query);

  const { speciality, grade, rootSpeciality, subSpeciality, locationName, ...rest } = purgedQuery;
  const newQuery: ParsedUrlQueryInput = { ...rest };

  if (locationName) {
    newQuery.locationName = locationName;
  } else {
    // generate locationName if missing
    const displayName = generateLocationNameFromLocationString(purgedQuery.location);
    if (displayName) {
      newQuery.locationName = displayName;
    }
  }

  return {
    pathname: generateJobListingsURL(grade, speciality, rootSpeciality, subSpeciality),
    query: newQuery,
  };
};

/**
 * Generates the root-relative jobs details URL. The result is in one of the following formats
 * /jobs/grade/speciality/jnxxxxxxxx
 * /jobs/grade/jnxxxxxxxx
 * /jobs/jnxxxxxxxx
 *
 * @param jobNumber the human friendly job reference number from Salesforce
 * @param grade the grade the job is for, if any
 * @param speciality the specialty the job is for, if any
 * @returns the canonical URL for rendering the job page
 */
export const generateJobDetailsURL = (jobNumber?: string, grade?: string, speciality?: string) => {
  if (grade && speciality && jobNumber) {
    return `${PATH.JOBS}/${slug(grade.toLowerCase())}/${slug(
      speciality.toLowerCase(),
    )}/${jobNumber.toLowerCase()}`;
  }

  if (grade && jobNumber) {
    return `${PATH.JOBS}/${slug(grade.toLowerCase())}/${jobNumber.toLowerCase()}`;
  }

  if (jobNumber) {
    return `${PATH.JOBS}/${jobNumber.toLowerCase()}`;
  }

  return '';
};

export const generateJobListTrackingDetails = (
  queryData: JobSearchWebCriteria,
  user?: User | null | undefined,
) => ({
  logged_in: user ? true : false,
  grade: queryData['grade'],
  speciality: queryData['speciality'],
  work_type: queryData['workType'],
  start_date: queryData['startDate'],
  end_date: queryData['endDate'],
  keywords: queryData['keywords'],
  location: queryData['location'],
});
/**
 * Generate location info
 * @param city
 * @param locationDisplay
 * @param country
 * @returns
 */
export const generateLocationInfo = (locationDisplay: string, country?: string, city?: string) => {
  const countryDetails = country ? ` ${country}` : '';
  if (city) {
    return `${city}, ${locationDisplay}${countryDetails}`;
  }
  return `${locationDisplay}${countryDetails}`;
};

/**
 *  Clean up search query data for job alerts use
 */

const jobAlertQueryParams = new Set([
  'grade',
  'speciality',
  'location',
  'workType',
  'startDate',
  'endDate',
  'keywords',
]);

export function convertJobSearchQueryToJobAlertQuery(query: JobSearchWebCriteria): IQuery {
  const newQuery = { ...query };

  for (const [key] of Object.entries(newQuery)) {
    if (!jobAlertQueryParams.has(key)) {
      delete newQuery[key];
    }
  }

  if (query.location) {
    newQuery.location = convertLocationsToValues(newQuery.location);
  }

  if (newQuery.grade === 'none') {
    delete newQuery.grade;
  }

  return newQuery as IQuery;
}

/**
 *  Convert Job Alert query params into displayable strings
 */

export function getJobAlertQueryDisplayValues(query: IQuery): string[] {
  const list: string[] = [];
  if (query?.keywords) {
    list.push(query.keywords);
  }

  if (query?.location) {
    const arrayLocations = convertLocationStringToLocationDisplayList(query.location);
    list.push(arrayLocations.join(', '));
  } else {
    list.push(ALL_LOCATIONS);
  }

  if (query?.grade) {
    list.push(query.grade);
  }

  if (query?.speciality) {
    list.push(query.speciality);
  }

  if (query?.workType) {
    list.push(query.workType);
  }

  if (query?.startDate || query?.endDate) {
    const dateTagFormat = 'DD/MM/YYYY';

    const endD = query?.endDate ? ` ~ ${dayjs(query?.endDate).format(dateTagFormat)}` : '';
    const startD = query?.startDate ? `${dayjs(query?.startDate).format(dateTagFormat)}` : 'NOW';

    list.push(`${startD}${endD}`);
  }

  return list;
}

export const parseQueryForGetJobs = (query: ParsedUrlQuery) => {
  const convertedQuery = { ...query };

  if (convertedQuery.location) {
    convertedQuery.location = convertLocationsToValues(convertedQuery.location as string);
  }
  if (convertedQuery.startDate) {
    convertedQuery.startDate =
      parseAndFormatDate(convertedQuery.startDate as string, 'YYYY-MM-DD') ?? undefined;
  }
  if (convertedQuery.endDate) {
    convertedQuery.endDate =
      parseAndFormatDate(convertedQuery.endDate as string, 'YYYY-MM-DD') ?? undefined;
  }
  if (convertedQuery.rootSpeciality) {
    convertedQuery.speciality = convertedQuery.rootSpeciality;
    convertedQuery.rootSpeciality = undefined;
  }

  return convertedQuery;
};

export const extractPerfectlyMatchingResults = (
  jobs: JobListPaginatedResults,
): JobListPaginatedResults => {
  const perfectlyMatchingJobs = jobs.data.filter(
    job => !job.matched || job.matched.partial === false,
  );

  const perfectJobResults: JobListPaginatedResults = {
    limit: jobs.limit,
    page: jobs.page,
    total: jobs.total,
    data: perfectlyMatchingJobs,
  };

  return perfectJobResults;
};

export const extractSimilarJobs = (jobs: JobSearchResultDetail[]): IJobDetail[] => {
  const similarJobs: JobSearchResultDetail[] = jobs.filter(job => {
    return job.matched && job.matched.partial === true;
  });

  return similarJobs;
};
